import { getWindowHref } from "@utils";
import BadRequest, { BadRequestData } from "components/BadRequest";
import {
  EncryptedGetServerSideProps,
  withEncryptionServerSide,
} from "context/AppEncryptionContext";
import I18nContext, { I18nContextData } from "i18n/context/LanguageContext";
import { Language, LanguageCode } from "i18n/types";
import type { NextPage, GetServerSideProps } from "next";
import { setCookie } from "nookies";
import { getConfiguration } from "page-services/configuration.service";
import {
  getErrorPage400Data,
  getSiteSettings,
  getStackData,
} from "page-services/helper.service";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorPages } from "types/ErrorPage.interface";
import gtmUtils from "utils/gtm";

interface Props {
  currentLanguage: Language;
  defaultLanguage: Language;
  supportedLanguages: Record<LanguageCode, Language>;
  stackName: string;
  siteSettings: {
    cookies: {
      cta_text: string;
      description: string;
    };
    error_pages: ErrorPages;
    font_family: string;
    font_face: string;
  };
  badRequestData: BadRequestData;
  publicConfigurations: {
    publicRootUrl: string;
    publicCountryCode: string;
    apiAuthBase64: string;
  };
}

const Page: NextPage<Props> = (props: Props) => {
  const { siteSettings, badRequestData } = props;
  const [language, setLanguage] = useState<Language>(props.currentLanguage);
  const [loaded, setLoaded] = useState<boolean>(false);
  const changeLanguage = useCallback(
    (locale: string) => {
      const language = props.supportedLanguages[locale];
      setLanguage(language);
      document
        ?.querySelector("body")
        ?.classList.toggle(`lang-${language?.languageCode}`);
    },
    [props.supportedLanguages]
  );

  useEffect(() => {
    setCookie(null, "lang", language.languageCode, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }, [language?.languageCode]);

  const i18nContextData: I18nContextData = useMemo(
    () => ({
      language,
      supportedLanguages: props.supportedLanguages,
      defaultLanguage: props.defaultLanguage,
      changeLanguage: changeLanguage,
      countryStackName: props.stackName,
      countryCode: props.publicConfigurations?.publicCountryCode,
      siteSettings: siteSettings,
      seo: null,
    }),
    [
      changeLanguage,
      language,
      props.defaultLanguage,
      props.publicConfigurations?.publicCountryCode,
      props.stackName,
      props.supportedLanguages,
      siteSettings,
    ]
  );

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      gtmUtils.pushEvent({
        event: "error",
        url: getWindowHref(),
        errorCode: "400",
      });
    }
  }, [loaded]);

  return (
    <I18nContext.Provider value={i18nContextData}>
      <BadRequest data={badRequestData || {}} />
    </I18nContext.Provider>
  );
};

export const getServerSideProps: EncryptedGetServerSideProps<Props> =
  withEncryptionServerSide(async (ctx) => {
    let { params } = ctx;

    const languageCode = params?.lang as string;
    const stackData = await getStackData();

    const currentLanguage: Language =
      stackData.languages[languageCode] || stackData.defaultLanguage;
    const siteSettings = await getSiteSettings(
      currentLanguage.locale,
      stackData,
      [
        "error_pages.body",
        "error_pages.footer.country_list",
        "error_pages.header.country_list",
        "error_pages.header.navigation_sections.major_grouping.disclosure_popup",
      ]
    );
    let badRequestData = await getErrorPage400Data(currentLanguage.locale);

    if (!badRequestData) {
      badRequestData = {};
    }

    const publicConfigurations = {
      publicRootUrl: getConfiguration(
        "NEXT_PUBLIC_ROOT_URL",
        process.env.NEXT_PUBLIC_ROOT_URL
      ),
      publicCountryCode: getConfiguration(
        "NEXT_PUBLIC_COUNTRY_CODE",
        process.env.NEXT_PUBLIC_COUNTRY_CODE
      ),
      apiAuthBase64: getConfiguration(
        "NEXT_PUBLIC_API_AUTH_BASE64",
        process.env.NEXT_PUBLIC_API_AUTH_BASE64
      ),
    };
    return {
      props: {
        currentLanguage,
        defaultLanguage: stackData.defaultLanguage,
        supportedLanguages: stackData.languages,
        stackName: stackData.stackName,
        siteSettings,
        badRequestData,
        publicConfigurations,
      },
    };
  });

export default Page;
