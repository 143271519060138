import { useRouter } from "next/router";
import { getUtmParams, setUtmParams } from "utils/utm";

export default function useUtmRouter() {
  const { push, ...router } = useRouter();

  const utm = getUtmParams();

  return {
    ...router,
    push: (url: string, as?: string, option?: any) => {
      const newAs = setUtmParams(utm, as || url);
      push(url, newAs, option);
    },
  };
}
