import styled from "styled-components";
import { colorsUtils, Text } from "@d2c-ui-components-react";
import Head from "next/head";
import { useRouter } from "next/router";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import I18nContext from "i18n/context/LanguageContext";
import gtmUtils from "utils/gtm";
import DropdownIcon from "../../public/dropdown.svg";
import { MenuItem, Select } from "@material-ui/core";
import { Button } from "@d2c-ui-components-react";
import { getWindowHref, replaceContentStackURL } from "@utils";
import { ModalPageNotFoundProvider } from "context/ModalPageNotFoundContext";
import DOMPurify from "isomorphic-dompurify";
import useUtmRouter from "hooks/useUtmRouter";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div<{ mt?: string }>`
  margin-top: ${({ mt }) => mt ?? 0};
  padding: 10rem 1rem 4rem 1rem;
  text-align: center;
`;

const Logo = styled.img`
  height: 34px;
`;

const Heading = styled.h1`
  font-weight: 300;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  padding: 10px 0;
`;

const Description = styled.div`
  a:hover {
    color: ${colorsUtils.darkGreen};
  }
  p {
    font-weight: 300;
    font-size: 1rem;
    color: ${colorsUtils.darkGreen};
    line-height: 1.5rem;
    padding: 10px 0;
  }
`;

const MarketSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
`;

const MarketSelectField = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
`;

const CtaWrapper = styled.div`
  max-width: 200;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
`;

export interface BadRequestData {
  title: string;
  error_title: string;
  error_description: string;
  market_title: string;
  environment_title: string;
  cta_title: string;
  logo?: {
    url: string;
  };
  markets: {
    name: string;
    environments: string[];
  }[];
}

export interface BadRequestProps {
  data: BadRequestData;
}

function BadRequest(props: BadRequestProps): JSX.Element {
  const { data } = props;
  const router = useUtmRouter();
  const [loaded, setLoaded] = useState<boolean>(false);
  const i18nContext = useContext(I18nContext);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded && typeof window !== "undefined") {
      gtmUtils.pushEvent({
        event: "error",
        url: getWindowHref(),
        errorCode: 400,
      });
    }
  }, [loaded]);

  const handleChangeMarket = (
    event: ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const market = event.target.value;
    setSelectedMarket(market);
    const environment = data?.markets.find((i) => i.name === market)
      ?.environments?.[0];
    if (environment) {
      setSelectedEnvironment(environment);
    } else {
      setSelectedEnvironment("");
    }
  };

  const handleChangeEnvironment = (
    event: ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const environment = event.target.value;
    setSelectedEnvironment(environment);
  };

  const handleSubmit = () => {
    if (selectedMarket && selectedEnvironment) {
      setLoading(true);
      router.push(`/?market=${selectedMarket}&env=${selectedEnvironment}`);
    }
  };

  return (
    <ModalPageNotFoundProvider>
      <Head>
        <title>{data?.title}</title>
      </Head>
      <Container>
        <Wrapper>
          {data?.logo?.url && (
            <Logo src={replaceContentStackURL(data?.logo?.url)} />
          )}
          <Heading>{data?.error_title}</Heading>
          <Description
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                resolveAbsoluteUrlInParagraph(
                  data?.error_description,
                  i18nContext
                )
              ),
            }}
          />
          <MarketSelectContainer>
            <MarketSelectField>
              <Text>{data?.market_title}</Text>
              <Select
                labelId="market"
                IconComponent={DropdownIcon}
                MenuProps={{
                  classes: {
                    paper: "mui-dropdown-menu",
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                value={selectedMarket}
                onChange={handleChangeMarket}
                disabled={loading}
              >
                {data?.markets?.map((market, index) => (
                  <MenuItem key={index} value={market.name}>
                    {market.name}
                  </MenuItem>
                ))}
              </Select>
            </MarketSelectField>
            <MarketSelectField>
              <Text>{data?.environment_title}</Text>
              <Select
                labelId="environment"
                IconComponent={DropdownIcon}
                MenuProps={{
                  classes: {
                    paper: "mui-dropdown-menu",
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                value={selectedEnvironment}
                onChange={handleChangeEnvironment}
                disabled={!selectedMarket || loading}
              >
                {data?.markets
                  ?.find((market) => market.name === selectedMarket)
                  ?.environments?.map((environment, index) => (
                    <MenuItem key={index} value={environment}>
                      {environment}
                    </MenuItem>
                  ))}
              </Select>
            </MarketSelectField>
          </MarketSelectContainer>
          <CtaWrapper>
            <Button
              onClick={handleSubmit}
              disabled={!selectedMarket || !selectedEnvironment || loading}
            >
              {data?.cta_title}
            </Button>
          </CtaWrapper>
        </Wrapper>
      </Container>
    </ModalPageNotFoundProvider>
  );
}

export default BadRequest;
